import { useLayout } from '@sbf/fairplay-core';
import { useEffect } from 'react';
import { logMparticle } from '@/common/utils/tracking/thirdParty/mParticle/init';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { useAuthSession } from '@/hooks/useAuthSession';

const MParticle = () => {
  const { isAuthenticated, isLoading, session } = useAuthSession();
  const { userData } = useTrackingContext();
  const { isMobile } = useLayout();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && session?.user?.email && userData?.id) {
        const userIdentityData = {
          email: session?.user?.email,
          customerid: userData.id,
        };
        logMparticle(userIdentityData, isMobile);
      } else if (!userData?.id && !isAuthenticated) {
        logMparticle({}, isMobile);
      }
    }
  }, [
    isAuthenticated,
    isLoading,
    session?.user?.email,
    userData?.id,
    isMobile,
  ]);

  return <></>;
};

export default MParticle;
