import { getMparticleConfig, startMParticle } from './trackingConfigs';
import { DataType } from './trackingConfigs.types';

export const logMparticle = (userIdentityData: DataType, isMobile = false) => {
  const mParticleConfig = getMparticleConfig(
    userIdentityData.customerid ? userIdentityData : undefined,
    isMobile,
  );
  startMParticle(mParticleConfig);
};
